import { observer } from "mobx-react-lite";
import { MouseEvent } from "react";

import { getCurrencySymbol } from "../../shared/helpers/getCurrencySymbol";
import { protectPageSectionOneNumberAnimation } from "../../shared/helpers/protectPageSectionOneNumberAnimation";
import { truncateWithEllipsis } from "../../shared/helpers/truncateWithEllipsis";
import { CardType } from "../../shared/models/Card";
import { useTranslation } from "../../shared/translate/NextI18next";
import { Currency } from "../../stores/DonateStore/DonateStore";
import { InfoBubble } from "../InfoBubble/InfoBubble";
import { M2Icon } from "../UonCountNumberAnimation/M2Icon";
import { UonCountNumberLocation } from "../UonCountNumberAnimation/StylesUonCountNumberAnimation";
import { UonCountNumberAnimation } from "../UonCountNumberAnimation/UonCountNumberAnimation";
import { sc } from "./CardItemPromotion.styled";

export type CardItemPromotionBottomDriver = {
  contentType: CardType.PROMOTION_REPOST | CardType.PROMOTION;
  collectionName: string;
  counter: number;
  protectedBy: string;
  currency: Currency;
  counterFlapEffectDuration: number;

  onShareButtonClicked(): void;
  isCollectButtonDisabled: boolean;
  onCollectButtonClicked(e: MouseEvent): void;

  hoverItem?: "LEARN_MORE" | "COLLECT"; // This props is for storybook only
};
interface IProps {
  driver: CardItemPromotionBottomDriver;
  noAnimationCounter?: boolean;
}

export const CardItemPromotionBottom = observer(
  function CardItemPromotionBottom(props: IProps) {
    const {
      collectionName,
      counter: uonsCount,
      protectedBy,
      onCollectButtonClicked,
      isCollectButtonDisabled,
      onShareButtonClicked,
      hoverItem,
      currency,
    } = props.driver;

    const { t } = useTranslation("CardItemPromotion");

    return (
      <sc.BottomContainer>
        <sc.Collect data-cy="CardItemPromotion-PromotionName">
          {truncateWithEllipsis(collectionName, 30)}
        </sc.Collect>
        <sc.Protect data-cy="CardItemPromotion-Description">
          {t("card-promotion.protect", {
            currency: getCurrencySymbol(currency),
          })}
          &nbsp;
          <InfoBubble driver={{ currency }} />
        </sc.Protect>
        <sc.CounterWrapper data-cy="CardItemPromotion-Counter">
          {protectPageSectionOneNumberAnimation(uonsCount).map((count, i) => (
            <UonCountNumberAnimation
              dataCy={"CardSystem-CountNumber"}
              flags={{
                counterFlapEffectDuration:
                  props.driver.counterFlapEffectDuration,
              }}
              noAnimationCounter={props.noAnimationCounter}
              countNumbers={count.countNumbers}
              lastNumber={count.lastNumber}
              // eslint-disable-next-line react/no-array-index-key
              key={`${count.lastNumber}${i}`}
              location={UonCountNumberLocation.CardSystem}
            />
          ))}
          <M2Icon location={UonCountNumberLocation.CardSystem} />
        </sc.CounterWrapper>
        <sc.ProtectedBy
          data-cy="CardItemPromotion-ProtectedBy"
          role="textbox" // use for unit test
        >
          {t("card-promotion.protected-by", {
            protectedBy: truncateWithEllipsis(protectedBy, 22),
          })}
        </sc.ProtectedBy>
        <sc.ButtonContainer>
          <sc.LearnMoreBtn
            className={hoverItem === "LEARN_MORE" ? "hover" : ""}
            data-cy="CardItemPromotion-ShareBtn"
            onClick={onShareButtonClicked}
          >
            {t("card-promotion.share-button")}
          </sc.LearnMoreBtn>
          <sc.CollectBtn
            className={hoverItem === "COLLECT" ? "hover" : ""}
            data-cy="CardItemPromotion-CollectBtn"
            onClick={onCollectButtonClicked}
            disabled={isCollectButtonDisabled}
          >
            {t("card-promotion.collect-button")}
          </sc.CollectBtn>
        </sc.ButtonContainer>
      </sc.BottomContainer>
    );
  },
);
